import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';

import { PageSection } from '../../components/page';
import Layout from '../../components/layouts/en';


const Contact = styled.div`
  color: ${(props) => props.theme.colorSecondary};
  text-align: center;

  a {
    text-decoration: none;
  }

  p {
    margin-bottom: 0;

    &:first-of-type {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
`


const PostContainer = styled(Container)`
  h1 {
    margin: 30px 15px;
  }

  h3 {
    font-size: 32px;
    margin: 30px 0;
  }
`


const PostList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  min-height: 100vh;
  padding: 0 15px;

  li {
    background-color: ${props => props.theme.colorSecondaryBG};
    flex-grow: 1;
    margin: 0 1% 30px;
    max-width: 48%;
    padding: 1em;
    width: 48%;

    @media (max-width: 767px) {
      max-width: 100%;
      width: 100%;
    }

    a {
      bottom: 0;
      color: ${props => props.theme.colorSecondary};
      display: block;
      height: 100%;
      position: relative;
      text-decoration: none;
      top: 0;

      &:hover {
        color: ${props => props.theme.colorButtonPrimaryBG};

        .gatsby-image-wrapper {
          transform: scale(1.1);
        }
      }

      & > div {
        overflow: hidden;

        .gatsby-image-wrapper {
          transition: ease .2s all;
        }
      }
    }

    em {
      display: block;
      font-size: 18px;
      font-style: normal;
      margin-bottom: 15px;
      margin-top: 5px;
    }

    p {
      color: ${props => props.theme.colorThird};
      font-size: 16px;
    }
  }
`


const Media = () => {
  const data = useStaticQuery(query);

  return <Layout>
    <PageSection>
      <PostContainer>
        <Row>
          <Col xs={12} md={9}>
            <h1><FormattedMessage id="MEDIA_H1" /></h1>
            <PostList>
              {data.allStrapiBrookfieldMedias.edges.map(({ node }) => {
                const image = node.photo && getImage(node.photo.localFile)
                return <li key={`post-${node.id}`}>
                  <a href={`/media/${node.slug}`}>
                    <div><GatsbyImage image={image} alt={node.title} /></div>
                    <em>{node.title}</em>
                    <p>{node.excerpt}</p>
                  </a>
                </li>
              })}
            </PostList>
          </Col>
          <Col xs={12} md={3}>
            <h3><FormattedMessage id="MEDIA_H3" /></h3>
            <Contact>
              <p>Jakub Sarzyński</p>
              <p><a href="tel:+48500246880">+48 500 246 880</a></p>
              <p><a href="mailto:jakub.sarzynski@brookfield.pl">jakub.sarzynski@brookfield.pl</a></p>
            </Contact>
          </Col>
        </Row>
      </PostContainer>
    </PageSection>
  </Layout>
};

const query = graphql`
  query {
    allStrapiBrookfieldMedias(
      sort: {
        order: DESC, fields: [publicationDate]
      }
    ) {
      edges {
        node {
          id
          slug
          title
          excerpt
          publicationDate
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 200
                  width: 350
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          published_at
        }
      }
    }
  }
`;

export default Media